<template>
  <div style="padding-top: 50px">
    <div class="BPCheaderIcon" :style="
      `background-image: url(${ logoTheme() ||
      (['404'].indexOf($route.params.state) < 0 && isErrorState
        ? '/favicon.png'
        : '/favicon-primary.png') });`
    "></div>
    <KTPortlet style="
        width: 500px;
        max-width: 90%;
        margin: 0 auto;
        xbackground: var(--dark);
      ">
      <template slot="body">
        <div class="loginBody" v-if="isErrorState" style="text-align: center">
          <h1 v-if="$route.params.state === '404'" style="color: var(--primary)">
            Page not found
          </h1>
          <h1 v-else style="color: var(--danger)">An error occured.</h1>

          <div style="padding: 10px; padding-top: 30px">
            <p v-if="$route.params.state === 'Tenant'">
              We couldn't find a tenant with this domain.
              <br /><br />Please contact support.
            </p>
            <p v-else-if="$route.params.state === 'Network'">
              We are having trouble communicating with the server.
              <br />Please check your internet connection and try again.
              <br /><br />Please contact support.
            </p>
            <p v-else-if="$route.params.state === '404'">
              Seems like the page you are looking for has grown some legs and
              wondered off.<br /><br /><br />
              <b-button @click="$router.push('/App/Dashboard')" variant="success text-light">Go home</b-button>
            </p>
            <p v-else>
              We're not sure what happened, but something did and we do not know
              how to handle it.
              <br /><br />Please contact support.
            </p>
          </div>
        </div>
        <div class="loginBody" v-else>
          <Loader :inContent="true" :noText="false" text="Waiting for session generation" />
        </div>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Loader from "@/views/partials/content/Loader.vue";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["layoutConfig", "config", "isAuthenticated", "logoTheme"]),

    isErrorState() {
      return this.$route.path.indexOf("/Auth/Error") === 0;
    }
  },
  components: {
    KTPortlet,
    Loader
  },
  mounted() {
    const self = this;
    self.$eventBus.on('ws-session', () => {
      self.$router.push("/Auth/Login");
    });
    if (this.isErrorState) return;
    self.$eventBus.on('cron-second"', () => {
      if (!self.$tools.isNullOrUndefined(self.$wsSessionId())) {
        self.$router.push("/Auth/Login");
      }
    });
  },
  beforeDestroy() {
    this.$eventBus.off(`ws-session`);
    this.$eventBus.off(`cron-second"`);
  }
};
</script>